import "./footer.css";

const Footer = () => {
  return (
    <p className="Footer">
      Nafiux® Internet © 2021. Autorización IFT{" "}
      <strong>FET100050AU-519427</strong>.
    </p>
  );
};

export default Footer;

import "./App.css";
import Header from "./components/header";
import Paquetes from "./components/paquetes";
import Instalacion from "./components/instalacion";
import Lanzamiento from "./components/lanzamiento";
import PreOrdena from "./components/preordena";
import Footer from "./components/footer";
import Title from "./components/title";

import "semantic-ui-css/components/dimmer.min.css";
import "semantic-ui-css/components/modal.min.css";
import "semantic-ui-css/components/image.min.css";
import "semantic-ui-css/components/button.min.css";
import "semantic-ui-css/components/icon.min.css";
import "semantic-ui-css/components/checkbox.min.css";
import "semantic-ui-css/components/reset.min.css";
import "./components/semantic-ui-fix.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Title />
      <Paquetes />
      <Instalacion />
      <Lanzamiento />
      <PreOrdena />
      <Footer />
    </div>
  );
}

export default App;

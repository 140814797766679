import "./title.css";

const Title = () => {
  return (
    <div>
      <p className="Title">
        Internet Inalámbrico Premium y Telefonía Ilimitada*
      </p>
    </div>
  );
};

export default Title;

import "./instalacion.css";

const Instalacion = () => {
  return (
    <div>
      <p className="Instalacion">Instalación $350 MXN</p>
    </div>
  );
};

export default Instalacion;

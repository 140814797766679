import React from "react";
import "./header.css";
import { Button, Image, Modal } from "semantic-ui-react";

import ImgAntena from "./HG3-CC-S90-GC-2.jpg";
import ImgLatencia from "./latencia.png";
import ImgQOS from "./QOS.jpg";
import SpeedIcon from "./speedIcon.png";
import TowerIcon from "./towerIcon.svg";
import WhatsappIcon from "./whatsapp_icon.svg";
import FacebookIcon from "./facebook_icon.svg";
import InstagramIcon from "./instagram_icon.svg";

const Header = () => {
  const [openConfiable, setOpenConfiable] = React.useState(false);
  const [openFAQ, setOpenFAQ] = React.useState(false);
  const [openContacto, setOpenContacto] = React.useState(false);

  return (
    <div>
      <div className="HeaderLeft">
        Nafiux <strong>Internet</strong>
        <img src={TowerIcon} className="TowerIcon" />
      </div>
      <div className="Menu">
        <Modal
          onClose={() => setOpenConfiable(false)}
          onOpen={() => setOpenConfiable(true)}
          open={openConfiable}
          trigger={<p className="MenuItem">Calidad de Servicio (QoS)</p>}
        >
          <Modal.Header>Calidad de Servicio (QoS)</Modal.Header>
          <Modal.Content image>
            <Image size="large" src={ImgQOS} wrapped />
            <Modal.Description>
              <p>
                El centro de datos de <strong>Nafiux Internet</strong> cuenta
                con una conexión a internet dedicada de fibra óptica y de baja
                latencia que es monitoreada 24 x 7.
              </p>
              <p>
                Además de un inteligente y avanzado sistema de{" "}
                <a
                  href="https://es.wikipedia.org/wiki/Calidad_de_servicio"
                  target="_blank"
                >
                  calidad en el servicio (QoS)
                </a>{" "}
                que distribuye el internet contratado a todos tus dispositivos,
                evitando que uno solo consuma todo el ancho de banda, reduciendo
                así la <strong>latencia</strong> y mejorando tu experiencia de
                navegación, especialmente en aplicaciones sensibles como video
                llamadas y juegos.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {/* <Button color="black" onClick={() => setOpenConfiable(false)}>
              Nope
            </Button> */}
            <Button
              content="OK"
              labelPosition="right"
              icon="checkmark"
              onClick={() => setOpenConfiable(false)}
              positive
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => setOpenFAQ(false)}
          onOpen={() => setOpenFAQ(true)}
          open={openFAQ}
          trigger={<p className="MenuItem">Preguntas Frecuentes</p>}
        >
          <Modal.Header>Preguntas Frecuentes</Modal.Header>
          <Modal.Content image>
            <Image size="medium" src={ImgAntena} wrapped />
            <Modal.Description>
              <ol className="ListaPreguntas">
                <li>
                  <span className="Pregunta">
                    ¿Cuánto cuesta contratar el servicio?
                  </span>
                  <span className="Respuesta">
                    Sólo debes cubrir el costo de la instalación de $350 MXN el
                    día que uno de nuestros técnicos te visite.
                    <br />
                    <br />
                    Los equipos de comunicación (receptor y modem) te los
                    prestamos (comodato) mientras tengas contratado el servicio,
                    mismos que deben ser regresados al momento de la
                    cancelación.
                  </span>
                </li>
                <li>
                  <span className="Pregunta">
                    ¿Cada cuánto debo pagar el servicio?
                  </span>
                  <span className="Respuesta">
                    Los primeros 5 días del mes siguiente. Te entregamos tu
                    recibo el último día del mes el cuál describe los distintos
                    métodos de pago que ofrecemos.
                  </span>
                </li>
                <li>
                  <span className="Pregunta">
                    ¿Cuál es la cobertura del servicio?
                  </span>
                  <span className="Respuesta">
                    Actualmente sólo en Tehuixtla, Morelos y colonias cercanas.
                  </span>
                </li>
                <li>
                  <span className="Pregunta">
                    ¿Qué incluye la telefonía ilimitada?
                  </span>
                  <span className="Respuesta">
                    Llamadas ilimitadas a números celulares y fijos en México,
                    Estados Unidos y Canada. Disponible sólo para usuarios
                    residenciales y pequeños negocios.
                    <br />
                    <br />
                    Las líneas telefónicas que entregamos inician con lada 777,
                    también podemos entregarte ladas de las ciudades más
                    importantes de México.
                    <br />
                    <br />
                    El servicio de telefonía depende del servicio de internet.
                    No ofrecemos líneas telefónicas solamente.
                  </span>
                </li>
              </ol>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {/* <Button color="black" onClick={() => setOpenRobusto(false)}>
              Nope
            </Button> */}
            <Button
              content="OK"
              labelPosition="right"
              icon="checkmark"
              onClick={() => setOpenFAQ(false)}
              positive
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => setOpenContacto(false)}
          onOpen={() => setOpenContacto(true)}
          open={openContacto}
          trigger={<p className="MenuItem">Contacto</p>}
        >
          <Modal.Header>Contacto</Modal.Header>
          <Modal.Content image>
            <Image size="small" src={WhatsappIcon} wrapped />
            <Modal.Description>
              <p>
                Te invitamos a contactarnos preferentemente via Whatsapp para
                brindarte información y atención personalizada.
              </p>
              <p>
                <img src={WhatsappIcon} className="ContactoIcon" />
                <a href="https://wa.me/+5217779802170" target="_blank">
                  777 980 2170
                </a>{" "}
                <strong>(Recomendado)</strong>
              </p>
              <p>
                <img src={FacebookIcon} className="ContactoIcon" />{" "}
                <a
                  href="https://www.facebook.com/NafiuxInternet"
                  target="_blank"
                >
                  NafiuxInternet
                </a>
              </p>
              <p>
                <img src={InstagramIcon} className="ContactoIcon" />{" "}
                <a
                  href="https://www.instagram.com/NafiuxInternet"
                  target="_blank"
                >
                  NafiuxInternet
                </a>
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            {/* <Button color="black" onClick={() => setOpenConfiable(false)}>
              Nope
            </Button> */}
            <Button
              content="OK"
              labelPosition="right"
              icon="checkmark"
              onClick={() => setOpenContacto(false)}
              positive
            />
          </Modal.Actions>
        </Modal>
      </div>
      {/* <a className="HeaderRight" href="https://fast.com" target="_blank">
        <img src={SpeedIcon} className="SpeedIcon" />
      </a> */}
      <a
        className="HeaderRight"
        href="https://wa.me/+5217779802170"
        target="_blank"
      >
        <img src={WhatsappIcon} className="HeaderIcon" />
      </a>
    </div>
  );
};

export default Header;

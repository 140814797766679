import React from "react";
import "./preordena.css";
import { Button, Image, Modal } from "semantic-ui-react";

const PreOrdena = () => {
  const [openPropuestaValor, setOpenPropuestaValor] = React.useState(false);

  return (
    <div className="Wrapper">
      <a
        className="PreOrdena"
        href="https://docs.google.com/forms/d/e/1FAIpQLScczo2CTNBWgL0BpWH8ar31o14VO_JGVm17dqY8vStsBPQIRg/viewform?hl=es"
        target="_blank"
      >
        Solicitar servicio
      </a>
      <Modal
        onClose={() => setOpenPropuestaValor(false)}
        onOpen={() => setOpenPropuestaValor(true)}
        open={openPropuestaValor}
        trigger={<a className="PropuestaValor">Propuesta de valor</a>}
      >
        <Modal.Header>Propuesta de valor</Modal.Header>
        <Modal.Content image>
          {/* <Image size="medium" src={ImgLatencia} wrapped /> */}
          <Modal.Description>
            <p>
              <strong>Internet Nafiux</strong> es un servicio residencial
              premium impulsado por un sistema avanzado de calidad en el
              servicio.
            </p>
            <p>
              Existen principalmente dos tipos de servicios de internet:{" "}
              <strong>empresarial (dedicado)</strong> y{" "}
              <strong>residencial (con sobresuscripción)</strong>.
            </p>
            <p>
              El internet <strong>empresarial</strong> es un enlace dedicado 1:1
              que garantiza la velocidad contratada todo el tiempo sin importar
              que tan congestionada este la red y cuesta más de 30 veces que el
              residencial.
            </p>
            <p>
              El internet <strong>residencial</strong> es un enlace compartido
              que no garantiza la velocidad contratada todo el tiempo y
              tipicamente se ve afectado cuando la red se congestionada.
            </p>
            <p>
              <strong>Todas</strong> las operadoras de telecomunicaciones
              (incluyendo Nafiux Internet) sobresuscriben el enlace para poder
              ofrecer un precio accesible a los usuarios finales.
            </p>
            <p>
              Nuestro servicio incluye un inteligente y avanzado sistema de{" "}
              <a
                href="https://es.wikipedia.org/wiki/Calidad_de_servicio"
                target="_blank"
              >
                calidad en el servicio (QoS)
              </a>{" "}
              que distribuye el internet contratado a todos tus dispositivos,
              evitando que uno solo consuma todo el ancho de banda, reduciendo
              así la <strong>latencia</strong> y mejorando tu experiencia de
              navegación, especialmente en aplicaciones sensibles como video
              llamadas y juegos.
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {/* <Button color="black" onClick={() => setOpen(false)}>
              Nope
            </Button> */}
          <Button
            content="OK"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setOpenPropuestaValor(false)}
            positive
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default PreOrdena;

import "./opciones.css";
import { Checkbox } from "semantic-ui-react";

const Opciones = (props) => {
  return (
    <div className="Opciones">
      <Checkbox
        toggle
        label={"Telefonía Ilimitada* $79 MXN"}
        onChange={(event, data) => props.onChange({ telefonia: data.checked })}
        alt
      />
    </div>
  );
};

export default Opciones;

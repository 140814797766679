import "./lanzamiento.css";

const Lanzamiento = () => {
  return (
    <div>
      <div className="TextoLanzamiento">Telefonía disponible a partir del</div>
      <div className="FechaLanzamiento">14 de Junio</div>
    </div>
  );
};

export default Lanzamiento;

import "./paquete.css";
import Circle from "./circle";
import { Popup } from "semantic-ui-react";
import "semantic-ui-css/components/popup.min.css";

const Paquete = (props) => {
  return (
    <div className="Paquete">
      <p className="Velocidad">{props.megas} Mb</p>
      <p className="Precio">
        <span className="PrecioMonto">
          ${Number(props.precioNormal) + props.telefonia}
        </span>
        /mes
        <br />
        {props.promo.includes("2021-50Init") && (
          <Popup
            position="top center"
            on="click"
            trigger={<p className="TextoPromocion">Promoción *</p>}
            content="$50 MXN de descuento por 12 meses e instalación gratis. Promoción válida para las primeras 20 contrataciones hasta el 31 de Mayo de 2021. No válida con otras promociones."
          />
        )}
        {props.promo.includes("2021-FreeInstalacion") && (
          <Popup
            position="top center"
            on="click"
            trigger={<p className="TextoPromocion">Instalación gratis *</p>}
            content="Promoción válida hasta el 31 de Junio de 2021. No válida con otras promociones."
          />
        )}
        {props.promo.length == 0 && (
          <p className="Instalacion">Instalación $250</p>
        )}
      </p>
      <Circle />
    </div>
  );
};

export default Paquete;

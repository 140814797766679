import Paquete from "./paquete";
import Opciones from "./opciones";
import "./paquetes.css";
import { render } from "@testing-library/react";
import React from "react";

class Paquetes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { telefonia: false };
  }

  onChangeHandler(opciones) {
    this.setState({
      telefonia: opciones.telefonia,
    });
  }
  render() {
    return (
      <div>
        <Opciones onChange={(opciones) => this.onChangeHandler(opciones)} />
        <div className="Paquetes">
          <Paquete
            megas="10"
            telefonia={this.state.telefonia ? 79 : 0}
            precioNormal="399"
            promo={[""]}
          />
          <Paquete
            megas="20"
            telefonia={this.state.telefonia ? 79 : 0}
            precioNormal="499"
            promo={[""]}
          />
          <Paquete
            megas="30"
            telefonia={this.state.telefonia ? 79 : 0}
            precioNormal="599"
            promo={[""]}
          />
        </div>
      </div>
    );
  }
}

export default Paquetes;
